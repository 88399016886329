// import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpHandlerService } from './http-handler.service';
import { environment as env } from 'src/environments/environment';

import { UserData } from '../models/user.data-object';
import { Constants } from '../models/constants';

import { ToastrService } from 'ngx-toastr';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppCoreService {

  isUserLoggedIn = new BehaviorSubject<boolean>(this.checkUserData());
  private selectedServiceCenterId = new BehaviorSubject<number>(this.GetServiceCenter());
  private creditDetails$ = new BehaviorSubject<{ limit: number, balance: number, used: number }>({ limit: 0, balance: 0, used: 0 });
  public reconciledAsset: any;
  private userDataSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('ud') || '{}'));

  private $shortcut = new BehaviorSubject<string>('');

  constructor(private Global: Constants,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.detectUrl();
  };


  private detectUrl() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.log("URL Changed:", event.urlAfterRedirects);
        // Update shortcut Based on URL
        this.resetShortcutOnURLChange(event.urlAfterRedirects);
      }
    });
  }

  private resetShortcutOnURLChange(url: string) {
    if (!url.includes('/operation/awb')) {
      this.updateShortcut('');
    } 
  }

  // Method to update shortcut visibility and content dynamically
  updateShortcut(shorcuts: string) {
    this.$shortcut.next(shorcuts);
  }
  
  getShortcutObservable(): Observable<string> {
    return this.$shortcut.asObservable();
  }

  // set credit details 
  public setCreditDetails(creditDetails: { limit: number, balance: number, used: number }) {
    this.creditDetails$.next(creditDetails);
  }

  //get credit details
  public getCurrentCreditDetails(): Observable<{ limit: number, balance: number, used: number }> {
    return this.creditDetails$.asObservable();
  }
  // abstract methods for local storage
  public getDataFromLS(key): any {
    return localStorage.getItem(key);
  }

  public setDataInLS(key, value): void {
    localStorage.setItem(key, value);
  }

  public removeDataFromLS(key): void {
    localStorage.removeItem(key);
  }

  public removeAllFromLS(): void {
    localStorage.clear();
  }

  // User section - start
  private checkUserData(): boolean {
    const saved_token = !!localStorage.getItem('ud');
    if (saved_token) {
      const userData: UserData = JSON.parse(localStorage.getItem('ud'));
      return userData.isUserLoggedIn;
    }
    return false;
  }

  public CheckUserLoggedIn(): Observable<boolean> {
    return this.isUserLoggedIn.asObservable();
  }

  public GetUserData(): UserData {
    const userData: UserData = JSON.parse(localStorage.getItem('ud'));
    return userData;
  }

  public getUserDataChanges(): Observable<any> {
    return this.userDataSubject.asObservable();
  }

  public SetUserData(value: any, loginRedirect = true): void {
    localStorage.setItem('ud', JSON.stringify(value));
    this.userDataSubject.next(value);
    if (loginRedirect) {
      this.isUserLoggedIn.next(true);
    }
  }

  public RemoveUserData(): void {
    localStorage.removeItem('ud');
    this.isUserLoggedIn.next(false);
  }

  // User section - ends

  private GetServiceCenter(): number {
    const saved_token = !!localStorage.getItem('ud');
    if (saved_token) {
      const userData: UserData = JSON.parse(localStorage.getItem('ud'));
      return userData.selectedServiceCenter;
    }
    return 0;
  }

  public SetServiceCenter(value: number): void {
    const saved_token = !!localStorage.getItem('ud');
    if (saved_token) {
      const userData: UserData = JSON.parse(localStorage.getItem('ud'));
      userData.selectedServiceCenter = value;
      localStorage.setItem('ud', JSON.stringify(userData));
      this.selectedServiceCenterId.next(value);
    } else {
      this.selectedServiceCenterId.next(0);
    }
  }

  public SelectedServiceCenter(): Observable<number> {
    return this.selectedServiceCenterId.asObservable();
  }

  

  /**
   * @description Check token value and validity and User flag
   * then return user validity
   * as of now isTokenValid: true, once tokenizaition will be enabled, the default value will be false
   * @returns user validity: true
   */
  isUserAuthenticated(): boolean {
    let isTokenValid = true;
    const isTokenEnable = env.apiTokenizationEnabled ? true : false;

    if (isTokenEnable) {
      // TODO:
      isTokenValid = true;
    }
    return this.getUserLoggedInStatus() && isTokenValid;
  }

  getUserLoggedInStatus(): boolean {
    return this.GetUserData() === null ? false : this.GetUserData().isUserLoggedIn;
  }

  // toastr messages
  showMessage(message, title = '', type = 'success'): void {
    if (type === 'error') {
      this.toastr.error(message, title);
    } else if (type === 'warning') {
      this.toastr.warning(message, title);
    } else if (type === 'info') {
      this.toastr.info(message, title);
    } else {
      this.toastr.success(message, title);
    }
  }

  setFocusConfig(value: any){
    const saved_token = !!localStorage.getItem('ud');
    if (saved_token) {
      const userData: UserData = JSON.parse(localStorage.getItem('ud'));
      userData.metaInfo.userconfig = value;
      localStorage.setItem('ud', JSON.stringify(userData));
    }
  }
}
